/**
 * Spoiler component.
 * 
 * @since 1.0.0
 * @param {jQuery} $
 * @returns {undefined}
 */
(function($) { 'use strict';
    var EVENT_NAMESPACE = '.warta.spoiler';
    var EVENT = {
        CLICK: 'click' + EVENT_NAMESPACE
    };
    
    $('.spoiler')
        .hide()
        .each(function() {
            var $spoiler = $(this);
            var $button = $('<button></button>', {  
                text: $spoiler.data('show-text'),
                class: $spoiler.data('button-class') || 'btn btn-primary btn-xs'
            });

            $button.insertBefore($spoiler);

            $button.on(EVENT.CLICK, function() {
                var $this = $(this);

                if($spoiler.data('type') === 'inline') {
                    $this.hide();
                    $spoiler.fadeIn();
                } else {
                    $spoiler.stop().slideToggle('_default', function() {                                                                                                                
                        $this.text($spoiler.data($spoiler.is(':visible') ? 'hide-text' : 'show-text'));
                    });            
                }
            }); 
        });
})(jQuery);