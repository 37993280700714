/**
 * Carousel caption.
 * 
 * @since 3.0.0
 * @param {Object} Warta
 * @param {jQuery} $
 * @returns {undefined}
 */
(function (Warta, $) { "use strict"; 
    var PLUGIN_NAME = 'wartaCarouselCaption';
    var EVENT_NAMESPACE = '.warta.carousel-caption';
    var EVENT = {
        SLIDE: 'slide.bs.carousel' + EVENT_NAMESPACE
    };
    
    /**
     * Constructor.
     * 
     * @since 3.0.0
     * @param {HTMLElement} element
     * @returns {Warta.CarouselCaption}
     */
    function Plugin(element) {
        this.$element = $(element);
        
        this._init();
    };
        
    /**
     * Add random classes to carousel caption items.
     * 
     * @since 3.0.0
     * @param {$.Event} e
     * @returns {undefined}
     */
    Plugin.prototype.addRandomClasses = function(e) {
        $(e.relatedTarget).find('.carousel-caption').each(function () {
            var elements = $(this).find('div').find('h1, h2, h3, h4, h5, h6, p');

            elements.each(function () {
                if (Math.round(Math.random()) === 1) {
                    $(this).addClass('rand-1').removeClass('rand-0');
                } else {
                    $(this).addClass('rand-0').removeClass('rand-1');
                }
            });
        });
    };
    
    /**
     * Initialize.
     * 
     * @since 3.0.0
     * @returns {undefined}
     */
    Plugin.prototype._init = function() {
        this.$element.on(EVENT.SLIDE, $.proxy(this.addRandomClasses, this));
    };
    
    /**
     * jQuery plugin wrapper around the constructor.
     * 
     * @since 3.0.0
     * @returns {jQuery}
     */
    $.fn[PLUGIN_NAME] = function() {
        return this.each(function() {
            // Preventing against multiple instantiations.
            if (!$.data(this, PLUGIN_NAME)) {
                $.data(this, PLUGIN_NAME, new Plugin(this));
            }
        });
    };    
    
    Warta.CarouselCaption = Plugin;
})(this.Warta = this.Warta || {}, jQuery);