/**
 * No mobile component.
 * 
 * Only display images on screen >= 768px. Remove element on smaller screens.
 * 
 * @since 1.0.0
 * @param {jQuery} $
 * @returns {undefined}
 */
(function ($) { 
    "use strict";
    
    var $noMobile = $('.no-mobile');

    if($(window).width() < 768) {
        $noMobile.remove();
        return;
    }
    
    /**
     * Display no-mobile block.
     * 
     * @since 3.0.0
     * @param {jQuery} $block 
     * @returns {undefined}
     */
    function display($block) {
        $block
            .css('display', 'block')
            .trigger('warta.no-mobile.shown');
    }

    $noMobile.each(function() {
        var $this = $(this);
        var $divImg = $noMobile.find('[data-src], [data-alt], [data-small], [data-medium], [data-large]');

        if($divImg.length > 0) {            
            $divImg.each(function () {
                var $divImg = $(this);
                var attributes = {};
                
                $.each(this.attributes, function() {
                    switch (this.name) {
                        case 'data-src':
                            attributes.src = this.value;
                            break;
                        case 'data-alt':
                            attributes.alt = this.value;
                            break;                            
                        default:
                            attributes[this.name] = this.value;
                            break;
                    }
                });
                
                $('<img>', attributes).replaceAll($divImg);
            });
            
            $this.imagesLoaded(function () {
                display($noMobile);
            });
        } else {
            display($noMobile);
        }
    });
})(jQuery);