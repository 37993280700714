/* global Modernizr */

/**
 * Slider tabs component.
 * 
 * @since 1.0.0
 * @param {Object} Warta
 * @param {jQuery} $
 * @returns {undefined}
 */
(function (Warta, $) { "use strict";
    var PLUGIN_NAME = 'wartaSliderTab';
    var EVENT_NAMESPACE = '.warta.slider-tab';
    var EVENT = {
        WINDOW: {
            RESIZE: 'resize' + EVENT_NAMESPACE
        },
        CONTAINER: {
            NO_MOBILE_SHOWN: 'warta.no-mobile.shown' + EVENT_NAMESPACE
        },
        CONTROL: {
            CLICK: 'click.control' + EVENT_NAMESPACE
        }
    };

    var isRtl = $('html').attr('dir') === 'rtl' || $('body').css('direction') === 'rtl';
  
    /**
     * Constructor.
     * 
     * @since 1.0.0
     * @param {HTMLElement} element 
     * @param {Object} options
     * @param {(jQuery|HTMLElement|string)} options.container
     * @returns {Warta.SliderTabs}
     */
    function Plugin(element, options) {
        this.options = $.extend({}, options);
        this.$tab = $(element);
        this.$container = this.options.container ? this.$tab.closest(this.options.container) : this.$tab.closest('.widget');

        var _this = this;
        var resizeTimer;
                
        this.activateControl();
        
        if(this.$container.hasClass('no-mobile')) {
            this.$container.on(EVENT.CONTAINER.NO_MOBILE_SHOWN, $.proxy(this.init, this));
        } else {
            this.init();
        } 
        
        $(window).on(EVENT.WINDOW.RESIZE, function () {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout($.proxy(_this.init, _this), 500);
        });
    };
    
    /*
     * Show the current tab controls.
     * 
     * @since 1.0.0
     */
    Plugin.prototype.activateControl = function () { 
        var self = this;
        
        this.$container.find('a[data-toggle="tab"]').off('show.bs.tab').on('show.bs.tab', function (e) {
            var control = self.$container.find('li.control'),
                target  = $(e.target).attr('href');

            control.removeClass('current');
            control.has('a[href="' + target + '"]').addClass('current');
        });
    };
    
    /*
     * Resize div.container-carousel to make all items stack horizontal.
     * 
     * @since 1.0.0
     */
    Plugin.prototype.resizeContent = function () {
        var ulWidth = (100 / this.maxItems) * this.totalItems,
            liWidth = 100 / this.totalItems;

        this.$ul.css('width', ulWidth + '%');
        this.$li.css('width', liWidth + '%');
    };
    
    /*
     * Adding sliding control.
     * 
     * @since 1.0.0
     */
    Plugin.prototype.slideControl = function () {
        var _this = this;
        var direction = isRtl ? 'right' : 'left';
        var maxPos = Math.ceil(this.totalItems / this.maxItems) - 1;
        var mod = (this.totalItems % this.maxItems);
        var maxMove = mod ? 
            -1 * ((maxPos - 1) * 100 + mod * 100 / this.maxItems) : 
            -1 * (maxPos * 100 + mod * 100 / this.maxItems);
                    
        // Reset position.
        this.position = 0;
        this.$ul.css(direction, 0);
        this.$ul.css('transform', 'translateX(0)');

        this.$control.off(EVENT.CONTROL.CLICK).on(EVENT.CONTROL.CLICK, function ( event ) {    
            if(isRtl) {
                $(this).data('slide') === 'next' ? _this.position-- : _this.position++;
            } else {
                $(this).data('slide') === 'next' ? _this.position++ : _this.position--;
            }

            var newMove = -1 * _this.position * 100;

            if(_this.position === maxPos || _this.position < 0) {
                newMove = maxMove;
                _this.position = maxPos;
            } else if(_this.position > maxPos) {
                newMove = 0;
                _this.position = 0;
            }    

            if (Modernizr.csstransforms) {
                var newMoveTranslate = newMove / 100 * _this.$ul.closest('.slider-container').width();
                newMoveTranslate = isRtl ? -1 * newMoveTranslate : newMoveTranslate;
                
                _this.$ul.css('transform', 'translateX(' + newMoveTranslate + 'px)');
            } else {                
                _this.$ul.css(direction, newMove + '%');
            }

            event.preventDefault();
        });
    };
    
    /*
     * Initialize.
     * 
     * @since 1.0.0
     */
    Plugin.prototype.init = function () { 
        var widgetWidth = this.$container.width();
           
        this.$control = this.$container.find('.control a').filter('[href="#' + this.$tab.attr('id') + '"]');  
        this.$ul = this.$tab.find('ul');
        this.$li = this.$ul.find('li');
        this.totalItems = this.$li.length;
                        
        if (widgetWidth < 570) {       
            this.maxItems = 2;
        } else if (widgetWidth < 720) {
            this.maxItems = 3;
        } else if(widgetWidth < 950) { 
            this.maxItems = 4;
        } else {
            this.maxItems = 6;
        }

        this.resizeContent();
        this.slideControl();
    };
    
    /**
     * jQuery plugin wrapper around the constructor.
     * 
     * @since 3.0.0
     * @param {Object} options
     * @returns {jQuery}
     */
    $.fn[PLUGIN_NAME] = function(options) {
        return this.each(function() {
            // Preventing against multiple instantiations.
            if (!$.data(this, PLUGIN_NAME)) {
                $.data(this, PLUGIN_NAME, new Plugin(this, options));
            }
        });
    };
    
    Warta.SliderTab = Plugin;

    if (!$.Slider) {
        /**
         * Old constructor.
         * 
         * @since 1.0.0
         * @deprecated since version 3.0.0
         * @param {Object} options 
         */
        $.Slider = function(options) {
            console.warn("WARNING! '$.Slider' has been deprecated, please use the new '$('#your-tab-pane')." + PLUGIN_NAME + "()' instead!");
            
            $(options.tab)[PLUGIN_NAME]({
                container: options.widget
            });
        };
    }
    
})(this.Warta = this.Warta || {}, jQuery);